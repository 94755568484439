/**
 *
 * SetList
 *
 */
import {
  DataGrid,
  GridColumnVisibilityModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import * as React from 'react';
import { columns } from './grid-column-def';
import { useNavigate } from 'react-router-dom';

type rows = {
  type: string;
  event: string;
  id: string;
};
interface Props {
  rows: rows[];
}

export function SetList(props: Props) {
  const navigate = useNavigate();
  const defaultHidden =
    window.innerWidth < 400
      ? {
          userLastName: false,
          created: false,
          startTime: false,
          type: false,
        }
      : {};
  const { rows } = props;
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [visabilityModel, setVisabilityModel] =
    React.useState<GridColumnVisibilityModel>(defaultHidden as any);
  return (
    <>
      {rowSelectionModel.length > 0 && <p>{rowSelectionModel[0]}</p>}
      <DataGrid
        density="compact"
        getRowId={row => row.id}
        rows={rows}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'startTime', sort: 'desc' }],
          },
          columns: {
            columnVisibilityModel: visabilityModel,
          },
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        columnVisibilityModel={visabilityModel}
        onColumnVisibilityModelChange={
          setVisabilityModel
            ? (visModel, details) => {
                setVisabilityModel(visModel);
              }
            : undefined
        }
        onRowSelectionModelChange={
          setRowSelectionModel
            ? (selectionModel, details) => {
                navigate(`review/${selectionModel[0].toString()}`, {});
                setRowSelectionModel(selectionModel);
              }
            : undefined
        }
      />
    </>
  );
}
