/**
 *
 * LanePinDeck
 *
 */

import React, { CSSProperties, MouseEventHandler } from 'react';
import { LANE_WIDTH, PIN_DECK_LENGTH } from '../Lane/constants';

import LanePinSpot from '../LanePinSpot';

export interface PinState {
  pin?: number;
  up?: boolean;
  color?: CSSProperties['color'];
  active?: boolean;
  onClick?: MouseEventHandler<SVGCircleElement>;
}
interface Props {
  pinState?: PinState[];
  big?: boolean;
}

export const LanePinDeck = (props: Props) => {
  const deckLength = PIN_DECK_LENGTH;
  const distanceBetweenPinRanks = (deckLength - 3) / 3;
  const { pinState, big } = props;
  const p = pin => pinState?.find(i => i.pin === pin);

  return (
    <>
      <rect
        strokeWidth={1}
        width={LANE_WIDTH}
        height={deckLength + 5}
        opacity={big ? 0 : 0.1}
      />
      <LanePinSpot key="7" x={2.75 + 0 * 12} y={3} {...p(7)} big={big} />
      <LanePinSpot key="8" x={2.75 + 1 * 12} y={3} {...p(8)} big={big} />
      <LanePinSpot key="9" x={2.75 + 2 * 12} y={3} {...p(9)} big={big} />
      <LanePinSpot key="10" x={2.75 + 3 * 12} y={3} {...p(10)} big={big} />

      <LanePinSpot
        key="5"
        x={LANE_WIDTH / 2}
        y={distanceBetweenPinRanks + 3}
        {...p(5)}
        big={big}
      />
      <LanePinSpot
        key="4"
        x={LANE_WIDTH / 2 - 12}
        y={distanceBetweenPinRanks + 3}
        {...p(4)}
        big={big}
      />
      <LanePinSpot
        key="6"
        x={LANE_WIDTH / 2 + 12}
        y={distanceBetweenPinRanks + 3}
        {...p(6)}
        big={big}
      />

      <LanePinSpot
        key="2"
        x={2.75 + 1 * 12}
        y={2 * distanceBetweenPinRanks + 3}
        {...p(2)}
        big={big}
      />
      <LanePinSpot
        key="3"
        x={2.75 + 2 * 12}
        y={2 * distanceBetweenPinRanks + 3}
        {...p(3)}
        big={big}
      />
      <LanePinSpot
        key="1"
        x={LANE_WIDTH / 2}
        y={3 * distanceBetweenPinRanks + 3}
        {...p(1)}
        big={big}
      />
    </>
  );
};

export default LanePinDeck;
