/**
 *
 * SetRecap
 *
 */
import * as React from 'react';
// import { GameLine } from '../ScoreLine/GameLine';
import { Box, Paper, Stack } from '@mui/material';
import { GameLine } from '../ScoreLine/GameLine';
import { useSelector } from 'react-redux';
import { selectGameManager } from '../GameManager/slice/selectors';
import { StatsFirstBallAvg } from '../StatsFirstBallAvg';
import { StatsStrikes } from '../StatsStrikes';
import { StatsSpares } from '../StatsSpares';
import { StatsSpareLeaves } from '../StatsSpareLeaves';
import { SetInfo } from './SetInfo';
import { AppActionsContext } from '../ResponsiveAppBar/AppActionsContext';

interface Props {}

export function SetRecap(props: Props) {
  const games: any = [];
  const game = useSelector(selectGameManager);
  const { setAppActions } = React.useContext(AppActionsContext);
  React.useEffect(() => {
    setAppActions(<></>);
  }, [setAppActions]);

  for (let i = 0; i < game.completedGames.length; i++) {
    console.log('adding game', game.completedGames[i]);
    games.push(
      game.completedGames[i].map((game, i) => ({
        ...game,
        frameNo: i + 1,
        key: { i },
      })),
    );
  }

  games.push(
    game.frames.map((game, i) => ({
      ...game,
      frameNo: i + 1,
      key: { i },
    })),
  );

  return (
    <Box m={2} mt={4}>
      <SetInfo />
      <Stack>
        {games.map((frames, i) => (
          <Stack direction="row">
            <GameLine
              showPins
              key={i}
              frames={frames}
              size={window.innerWidth > 600 ? 80 : window.innerWidth / 13}
            />
          </Stack>
        ))}
      </Stack>
      <Paper sx={{ backgroundColor: 'grey' }}>
        <Stack direction="row" spacing={2} margin={2}>
          <StatsStrikes frames={games} />
          <StatsSpares frames={games} />
          <StatsFirstBallAvg frames={games} />
          <StatsSpareLeaves frames={games} />
        </Stack>
      </Paper>
    </Box>
  );
}
