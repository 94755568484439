/**
 *
 * RecapsPage
 *
 */
import { SetList } from 'app/components/SetList';
import { SetRecap } from 'app/components/SetRecap';
import { useSetSearchSlice } from 'app/components/SetSearch/slice';
import { selectSetSearch } from 'app/components/SetSearch/slice/selectors';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useResolvedPath } from 'react-router-dom';

interface Props {}

export function RecapReview(props: Props) {
  const { actions } = useSetSearchSlice();
  const dispatch = useDispatch();
  const { setId } = useParams();
  const sets = useSelector(selectSetSearch);

  useSetSearchSlice();

  React.useEffect(() => {
    dispatch(actions.getSetApiStart({ id: setId || '' }));
  }, [actions, dispatch, setId]);

  if (setId && setId.length && sets.currentSet?.games) {
    return <SetRecap games={sets.currentSet?.games} />;
  }

  return <p>Loading...</p>;
}
