import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { setSearchSaga } from './saga';
import { SetSearchState } from './types';

export const initialState: SetSearchState = {
  rows: [],
};

const slice = createSlice({
  name: 'setSearch',
  initialState,
  reducers: {
    listSets(state) {},
    listSetsApiStart(state) {},
    listSetsApiSuccess(state, action: PayloadAction<any>) {
      state.rows = action.payload;
    },
    listSetsApiRetry(state, action: PayloadAction<any>) {},
    getSetApiStart(state, action: PayloadAction<{ id: string }>) {},
    getSetApiSuccess(
      state,
      action: PayloadAction<{ id: string; games: any[] }>,
    ) {
      state.currentSet = action.payload;
    },
    getSetApiRetry(state, action: PayloadAction<any>) {},
  },
});

export const { actions: setSearchActions } = slice;

export const useSetSearchSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: setSearchSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSetSearchSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
