/**
 *
 * SetRecap
 *
 */
import * as React from 'react';
// import { GameLine } from '../ScoreLine/GameLine';
import { Box, Paper, Stack } from '@mui/material';
import { GameLine } from '../ScoreLine/GameLine';
import { StatsFirstBallAvg } from '../StatsFirstBallAvg';
import { StatsStrikes } from '../StatsStrikes';
import { StatsSpares } from '../StatsSpares';
import { StatsSpareLeaves } from '../StatsSpareLeaves';
import { SetInfo } from './SetInfo';
import { AppActionsContext } from '../ResponsiveAppBar/AppActionsContext';
import { FrameSummary } from '../GameManager/slice/types';

interface Props {
  games: FrameSummary[][];
}

export function SetRecap(props: Props) {
  const { games } = props;
  const { setAppActions } = React.useContext(AppActionsContext);
  React.useEffect(() => {
    setAppActions(<></>);
  }, [setAppActions]);

  return (
    <Box m={2} mt={4}>
      <SetInfo />
      <Stack>
        {games.map((frames, i) => (
          <Stack direction="row">
            <GameLine
              showPins
              key={i}
              frames={frames as any}
              size={window.innerWidth > 600 ? 80 : window.innerWidth / 13}
            />
          </Stack>
        ))}
      </Stack>
      <Paper sx={{ backgroundColor: 'grey' }}>
        <Stack direction="row" spacing={2} margin={2}>
          <StatsStrikes frames={games} />
          <StatsSpares frames={games} />
          <StatsFirstBallAvg frames={games} />
          <StatsSpareLeaves frames={games} />
        </Stack>
      </Paper>
    </Box>
  );
}
