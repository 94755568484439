/**
 *
 * PatternViewer
 *
 */

import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  Link,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Lane from 'app/components/lane/Lane';
import { Logo } from 'app/components/Logo';
import { useLaneSlice } from 'app/components/lane/Lane/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectLane } from 'app/components/lane/Lane/slice/selectors';
import BasicModal from 'app/components/lane/Model';
import { useState } from 'react';
import { laneActions } from 'app/components/lane/Lane/slice';
import TransitionIcon from '@mui/icons-material/East';
import {
  LANE_TOTAL_LENGTH,
  LANE_WIDTH,
} from 'app/components/lane/Lane/constants';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useSearchParams } from 'react-router-dom';
import { Share, Clear, HighlightOff, Article } from '@mui/icons-material';
import { useWindowSize } from 'usehooks-ts';
import {
  targetListActions,
  useTargetListSlice,
} from 'app/components/TargetList/slice';
import { selectTargetList } from 'app/components/TargetList/slice/selectors';
import PatternSelectorDrawer from 'app/components/lane/Pattern/PatternSelectorDrawer';
import { isMobile } from 'react-device-detect';
import { ExternalLink } from 'utils/nav';
import { AppActionsContext } from 'app/components/ResponsiveAppBar/AppActionsContext';
import { notificationsActions } from 'app/components/Notifications/slice';
import { SensorData, SensorSample } from './sensorValues';
import { groupBy } from './util';
interface Props {}

export function TargetSensor(props: Props) {
  useTargetListSlice();
  useLaneSlice();
  const dispatch = useDispatch();
  const [isInitalized, setIsInitialized] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [saveTargetLabel, setSaveTargetLabel] = React.useState('');

  const lane = useSelector(selectLane);
  const [pageLoadTime, setPageLoadTime] = React.useState(new Date());
  const [openSetLaydown, setOpenSetLaydown] = React.useState(false);
  const [openSetTarget, setOpenSetTarget] = React.useState(false);
  const [openSetExitpoint, setOpenSetExitpoint] = React.useState(false);
  const [proposedNewTarget, setProposedNewMarker] = React.useState({
    board: -1,
    distance: -1,
  });
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const [sensorValues, setSensorValues] = React.useState<SensorData>();

  const { height } = useWindowSize();
  const { appActions, setAppActions } = React.useContext(AppActionsContext);

  React.useEffect(() => {
    if (!isInitalized && searchParams.get('tb') !== null) {
      dispatch(laneActions.setLine(searchParams.toString()));
    }
    setSearchParams(new URLSearchParams(lane.encodedLine), { replace: true });

    setIsInitialized(true);

    const Reset = () => (
      <Button
        sx={{ zIndex: 100 }}
        fullWidth={true}
        variant="contained"
        size="small"
        onClick={() => {
          // eslint-disable-next-line no-restricted-globals
          dispatch(laneActions.resetTarget());
        }}
        endIcon={<HighlightOff fontSize="small" />}
      >
        Reset
      </Button>
    );
    setAppActions([
      <Box key="actions" flex={1} sx={{ px: 2 }}>
        <Stack direction="row" spacing={1}>
          <Reset />
        </Stack>
      </Box>,
    ]);

    // const lastSensedTime = new Date(pageLoadTime.valueOf());
    const fastPoll = true;
    // const fastPoll =
    //   !sensorValues ||
    //   !sensorValues[0]?.datetime ||
    //   new Date(pageLoadTime.valueOf() + 1000 * 60 * 1) >
    //     new Date(sensorValues[0].datetime);
    const interval = setInterval(
      async () => {
        const response = await fetch(
          `${process.env.REACT_APP_API_URI}/sensor/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                'AWS_BEARER_TOKEN',
              )}`,
              'Content-Type': 'application/json',
            },
          },
        );
        const values: SensorSample[] = (await response.json()).values;
        const transformed = groupBy(values, 'sensorLabel');
        if (JSON.stringify(sensorValues) !== JSON.stringify(transformed)) {
          dispatch(
            notificationsActions.toast({
              component: <pre>{JSON.stringify(transformed, null, 2)}</pre>,
              options: {
                autoClose: 500,
                type: 'info',
              },
            }),
          );
        }
        console.log(transformed);
        setSensorValues(transformed);
      },
      fastPoll ? 3000 : 120000,
    );
    return () => clearInterval(interval);
  }, [
    searchParams,
    setSearchParams,
    lane,
    dispatch,
    isInitalized,
    height,
    setAppActions,
    sensorValues,
  ]);

  const heightScaleFactor =
    (height - 75 - (isMobile ? 0 : 45)) / LANE_TOTAL_LENGTH;

  const DrawerList = (
    <Box
      overflow={'visible'}
      role="presentation"
      minHeight={'100vh'}
      sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
    >
      <svg
        height="100vh"
        // width={'100%'}
        // width="100vw"
        // viewBox={`0 ${window.innerWidth / lane.yScaleFactor} ${
        //   window.innerHeight / 4
        // }`}
        // viewBox={`0 700 600 600`}
        preserveAspectRatio="xMaxYMin meet"
      >
        <g transform={`scale(${3 * heightScaleFactor},${heightScaleFactor})`}>
          <Lane
            selector={lane}
            sensor={sensorValues}
            showMarkerLabels={true}
            setOpenSetLaydown={() => setOpenSetLaydown(true)}
            setOpenSetTarget={() => setOpenSetTarget(true)}
            setOpenSetExitpoint={() => setOpenSetExitpoint(true)}
          ></Lane>
        </g>
      </svg>
    </Box>
  );
  const initialScale = 8;
  if (!isInitalized) return <></>;
  return (
    <>
      <Helmet>
        <title>Target Explorer</title>
        <meta name="description" content="Improve your bowling" />
      </Helmet>

      <Stack>
        <Button onClick={toggleDrawer(true)}>Show Targets</Button>
        <PatternSelectorDrawer />
      </Stack>
      {/* Break the screen up into content and large screen overflow */}
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
      <Stack spacing={3} direction={'row'}>
        <Stack direction="column">
          <Box width={'100vw'}>
            <Typography align="center" variant="h5">
              Down Lane
            </Typography>
            <TransformWrapper
              initialPositionX={0}
              initialPositionY={
                -(LANE_TOTAL_LENGTH - lane.exitDistance * 12 - 30) *
                initialScale
              }
              initialScale={initialScale}
              maxScale={20}
              onInit={ref => {
                ref.zoomIn(0.01);
              }}
            >
              <TransformComponent
                wrapperStyle={{
                  width: '100%',
                  height: '40vh',
                }}
              >
                <svg height={LANE_TOTAL_LENGTH} width={LANE_WIDTH}>
                  <Lane
                    selector={lane}
                    showMarkerLabels={false}
                    sensor={sensorValues}
                  ></Lane>
                </svg>
              </TransformComponent>
            </TransformWrapper>
          </Box>
          <Box width={'100vw'}>
            <Typography align="center" variant="h5">
              Target
            </Typography>
            <TransformWrapper
              initialPositionX={0}
              initialPositionY={
                -(LANE_TOTAL_LENGTH - lane.targetDistance * 12 - 30) *
                initialScale
              }
              initialScale={initialScale}
              maxScale={20}
              onInit={ref => {
                ref.zoomIn(0.01);
              }}
            >
              <TransformComponent
                wrapperStyle={{
                  width: '100%',
                  height: '40vh',
                }}
              >
                <svg height={LANE_TOTAL_LENGTH} width={LANE_WIDTH}>
                  <Lane
                    selector={lane}
                    showMarkerLabels={false}
                    sensor={sensorValues}
                  ></Lane>
                </svg>
              </TransformComponent>
            </TransformWrapper>
          </Box>
        </Stack>
      </Stack>
      <BasicModal open={openSetLaydown} setOpen={setOpenSetLaydown}>
        <>
          <Typography variant="h2">Laydown</Typography>
          <Typography variant="body2">
            Zoom/Pan to select your new laydown board
          </Typography>
          <Grid container padding={0} margin={0}>
            <Grid item xs={12} lg={12} m={12} border={1} margin={0} padding={0}>
              <TransformWrapper
                // centerOnInit={true}
                initialPositionX={0}
                initialPositionY={-(LANE_TOTAL_LENGTH - 30) * 5}
                // // centerOnInit={true}
                initialScale={5}
                maxScale={20}
                // limitToBounds={false}
                // onInit={ref => {
                //   ref.centerView(5);
                //   ref.zoomToElement('MarkerOverlay', 8);
                // }}
                // centerZoomedOut={true}
              >
                <TransformComponent
                  wrapperStyle={{ width: '100%', height: '40vh' }}
                >
                  <svg
                    height={LANE_TOTAL_LENGTH}
                    width={LANE_WIDTH}
                    // width={window.innerWidth}
                    // width={window.innerWidth}
                    // width="100vh"
                    // viewBox={`0 500 ${window.innerWidth / lane.yScaleFactor} ${
                    //   window.innerHeight / 4
                    // }`}
                    // viewBox={`0 700 600 600`}
                    // preserveAspectRatio="xMinYMin meet"
                  >
                    {/* <g
                  transform={`scale(${
                    1 * heightScaleFactor
                  },${heightScaleFactor})`}
                > */}
                    <Lane
                      selector={lane}
                      showMarkerLabels={false}
                      onBoardClick={(board, distance) => {
                        console.log(`${board} @ ${distance}`);
                        setProposedNewMarker({ board, distance });
                      }}
                    ></Lane>
                    {/* </g> */}
                  </svg>
                </TransformComponent>
              </TransformWrapper>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign={'center'} padding={2}>
                <Button
                  color="warning"
                  onClick={() => {
                    setProposedNewMarker({ board: -1, distance: -1 });
                    setOpenSetLaydown(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
            {proposedNewTarget.board !== -1 && (
              <>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="h3" paddingX={4}>
                      {lane.laydownBoard.toFixed(1)}
                    </Typography>
                    <TransitionIcon style={{ transform: `scale(2)` }} />
                    <Typography variant="h3" paddingX={4}>
                      {proposedNewTarget.board.toFixed(1)}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={12} lg={12} m={12} margin={0} padding={0}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyItems="center"
                    justifyContent="center"
                    spacing={3}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        dispatch(
                          laneActions.updateLaydownCalculateTarget(
                            proposedNewTarget.board,
                          ),
                        );
                        setProposedNewMarker({ board: -1, distance: -1 });
                        setOpenSetLaydown(false);
                      }}
                    >
                      Update Target
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        dispatch(
                          laneActions.updateLaydownCalculateExit(
                            proposedNewTarget.board,
                          ),
                        );
                        setProposedNewMarker({ board: -1, distance: -1 });
                        setOpenSetLaydown(false);
                      }}
                    >
                      Update Exit
                    </Button>
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </>
      </BasicModal>
      <BasicModal open={openSetTarget} setOpen={setOpenSetTarget}>
        <>
          <Typography variant="h2">Target</Typography>
          <Typography variant="body2">
            Zoom/Pan to select your new target board
          </Typography>
          <Grid container padding={0} margin={0}>
            <Grid item xs={12} lg={12} m={12} border={1} margin={0} padding={0}>
              <TransformWrapper
                initialPositionX={0}
                initialPositionY={
                  -(LANE_TOTAL_LENGTH - lane.targetDistance * 12 - 30) * 5
                }
                initialScale={5}
                maxScale={20}
              >
                <TransformComponent
                  wrapperStyle={{ width: '100%', height: '40vh' }}
                >
                  <svg height={LANE_TOTAL_LENGTH} width={LANE_WIDTH}>
                    <Lane
                      selector={lane}
                      showMarkerLabels={false}
                      onBoardClick={(board, distance) => {
                        console.log(`${board} @ ${distance}`);
                        setProposedNewMarker({ board, distance });
                      }}
                    ></Lane>
                  </svg>
                </TransformComponent>
              </TransformWrapper>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign={'center'} padding={2}>
                <Button
                  color="warning"
                  onClick={() => {
                    setProposedNewMarker({ board: -1, distance: -1 });
                    setOpenSetTarget(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
            {proposedNewTarget.board !== -1 && (
              <>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="h3" paddingX={4}>
                      {lane.targetBoard.toFixed(1)}
                    </Typography>
                    <TransitionIcon style={{ transform: `scale(3)` }} />
                    <Typography variant="h3" paddingX={4}>
                      {proposedNewTarget.board.toFixed(1)}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={12} lg={12} m={12} margin={0} padding={0}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyItems="center"
                    justifyContent="center"
                    spacing={3}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        dispatch(
                          laneActions.updateTargetCalculateLaydown({
                            ...proposedNewTarget,
                          }),
                        );
                        setProposedNewMarker({ board: -1, distance: -1 });
                        setOpenSetTarget(false);
                      }}
                    >
                      Update Laydown
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        dispatch(
                          laneActions.updateTargetCalculateExit({
                            ...proposedNewTarget,
                          }),
                        );
                        setProposedNewMarker({ board: -1, distance: -1 });
                        setOpenSetTarget(false);
                      }}
                    >
                      Update Exit
                    </Button>
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </>
      </BasicModal>
      <BasicModal open={openSetExitpoint} setOpen={setOpenSetExitpoint}>
        <>
          <Typography variant="h2">Pattern Exit</Typography>
          <Typography variant="body2">
            Zoom/Pan to select your new exit board
          </Typography>
          <Grid container padding={0} margin={0}>
            <Grid item xs={12} lg={12} m={12} border={1} margin={0} padding={0}>
              <TransformWrapper
                initialPositionX={0}
                initialPositionY={
                  -(LANE_TOTAL_LENGTH - lane.exitDistance * 12 - 30) * 5
                }
                initialScale={5}
                maxScale={20}
              >
                <TransformComponent
                  wrapperStyle={{ width: '100%', height: '40vh' }}
                >
                  <svg height={LANE_TOTAL_LENGTH} width={LANE_WIDTH}>
                    <Lane
                      selector={lane}
                      showMarkerLabels={false}
                      onBoardClick={(board, distance) => {
                        console.log(`${board} @ ${distance}`);
                        setProposedNewMarker({ board, distance });
                      }}
                    ></Lane>
                  </svg>
                </TransformComponent>
              </TransformWrapper>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign={'center'} padding={2}>
                <Button
                  color="warning"
                  onClick={() => {
                    setProposedNewMarker({ board: -1, distance: -1 });
                    setOpenSetExitpoint(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
            {proposedNewTarget.board !== -1 && (
              <>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="h3" paddingX={4}>
                      {lane.exitBoard.toFixed(1)}
                    </Typography>
                    <TransitionIcon style={{ transform: `scale(3)` }} />
                    <Typography variant="h3" paddingX={4}>
                      {proposedNewTarget.board.toFixed(1)}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={12} lg={12} m={12} margin={0} padding={0}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyItems="center"
                    justifyContent="center"
                    spacing={3}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        dispatch(
                          laneActions.updateExitCalculateLaydown({
                            ...proposedNewTarget,
                          }),
                        );
                        setProposedNewMarker({ board: -1, distance: -1 });
                        setOpenSetExitpoint(false);
                      }}
                    >
                      Update Laydown
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        dispatch(
                          laneActions.updateExitCalculateTarget({
                            ...proposedNewTarget,
                          }),
                        );
                        setProposedNewMarker({ board: -1, distance: -1 });
                        setOpenSetExitpoint(false);
                      }}
                    >
                      Update Target
                    </Button>
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </>
      </BasicModal>
    </>
  );
}
