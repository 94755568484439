import React, { MouseEventHandler, TouchEventHandler } from 'react';

import { BOARD_WIDTH, LANE_TOTAL_LENGTH, LANE_WIDTH } from './constants';

interface Props {
  angle: number;
  x: number;
  y: number;
}

export const LaunchAngle = (props: Props) => {
  // eslint-disable-line react/prefer-stateless-function

  const { angle, x, y } = props;
  return (
    <svg pointerEvents="none">
      <text
        fontFamily="Verdana"
        transform="scale(1,2)"
        fontSize={6}
        x={x}
        y={y / 2}
        width={100}
        height={120}
      >
        {((180 / Math.PI) * angle).toFixed(1)}°
      </text>
    </svg>
  );
};

export default LaunchAngle;
